export default function validateRegisterInfo(values) {
    let errors = {};

    if (!values.firstname) {
        errors.firstname = "First name is required.";
    } else if (values.firstname.trim().length < 2) {
        errors.firstname = "Name is a little too short.";
    }

    if (!values.lastname) {
        errors.lastname = "Last name is required.";
    } else if (values.lastname.trim().length < 2) {
        errors.lastname = "Name is a little too short.";
    }

    if (!values.companyname) {
        errors.companyname = "Company name is required.";
    } else if (values.companyname.trim().length < 2) {
        errors.companyname = "Company name is a little too short.";
    }

    if (!values.email) {
        errors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(values.email.trim())) {
        errors.email = "Email address is invalid.";
    }

    if (!values.phonenumber) {
        errors.phonenumber = "Phone number is required.";
    } else if (
        !/[0-9\b]+$/.test(values.phonenumber.trim()) ||
        values.phonenumber.trim().length !== 10
    ) {
        errors.phonenumber = "Phone number must be 10 digits long.";
    }

    if (!values.password) {
        errors.password = "Password is required.";
    } else if (values.password.trim().length < 6) {
        errors.password = "Password needs to be 6 characters or more.";
    } else {
        if (!values.password2) {
            errors.password2 = "Password confirmation is required.";
        } else if (values.password2.trim() !== values.password.trim()) {
            errors.password2 = "Passwords do not match.";
        }
    }

    return errors;
}
